import React from "react"
import Layout from "../components/layout"

const CoursesPage = () => (
  <Layout>
    <h2>Kurs</h2>
    <br />
    <p>Ta kontakt med oss for kursdatoer og ytterligere informasjon</p>
    <br />

    <h4>Kurs om sikring av last, Klasse BE, D1E, DE og T</h4>
    <p>
      Kurset er på 2 timer, teori og praksis. Eleven skal forstå behovet for å
      sikre og merke last, og få nødvendig øving i å sikre last på tilhenger.
    </p>
    <br />

    <h4>Trafikalt grunnkurs</h4>
    <p>
      Før du kan ta kjøretimer eller øvelseskjøre, må du ta trafikalt grunnkurs.
      Kurset er på 17 undervisningstimer og vil gi deg en grunnleggende
      forståelse for hva det innebærer å være fører. Du må være minst 15 år for
      å ta kurset. Som en del av kurset er det 4 undervisningstimer om
      førstehjelp.
    </p>

    <h4>Mørkekjøring</h4>
    <p>
      Mellom 1. november og 15. mars må du ha gjennomført mørkekjøring for å
      kunne øvingskjøre og ta førerprøve. Kurset gjennomføres i tiden september
      til april.
    </p>
    <br />
  </Layout>
)

export default CoursesPage
